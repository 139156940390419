
// Modules
import React from 'react'
import Img from 'gatsby-image'

// Styles
const bgImgStyles = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: -1
}

export default ({ sizes = null }) => (
  sizes ? <Img sizes={sizes} style={bgImgStyles} /> : null
)
