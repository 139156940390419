
// Modules
import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import BgImage from '../components/bg-image'

// Styles
const parentStyles = {}
const heroSection = {
  padding: '7rem 2rem 2rem'
}
const h1Styles = {
  margin: '-7rem 0 0.5rem',
  fontWeight: '700',
  fontSize: '8rem',
  textShadow: '0 0 4rem rgba(0, 0, 0, 0.4)',
  color: '#fff',
  textAlign: 'center'
}
const h2Style = {
  fontStyle: 'normal',
  fontWeight: '700',
  background: '#fff',
  padding: '1rem 4rem',
  marginBottom: '1.5rem',
  marginLeft: '0',
  marginRight: '0',
  boxShadow: '0 10px 40px rgba(0, 0, 0, 0.4)',
  textAlign: 'center'
}

// Export
export default ({ data }) => {
  const title = data.site.siteMetadata.title
  const description = data.site.siteMetadata.description
  const sizes = data.file.childImageSharp.sizes

  return (
    <div style={parentStyles}>
      <Helmet>
        <title>{title}</title>
        <meta name='description' content={description} />
      </Helmet>
      <BgImage sizes={sizes} />
      <section style={heroSection}>
        <div style={{ boxShadow: '0 20px 40px rgba(0, 0, 0, 0.2)' }}>
          <div style={{ padding: '2rem', background: 'rgba(255, 255, 255, 0.85)', color: '#1c1c1c', borderBottom: '4px solid #fb002d' }}>
            <h1 style={h1Styles}>K10L</h1>
            <h2 style={h2Style}>Köhler / Lessel</h2>
            <h2 style={{ fontWeight: '100', marginBottom: 0, textAlign: 'center' }}>Business Development<br />& Software Consulting</h2>
          </div>
        </div>
      </section>
    </div>
  )
}

export const query = graphql`
  query {
    file(relativePath: { eq: "img/body-bg.jpg" }) {
      childImageSharp {
        sizes (maxWidth: 1920) {
          ...GatsbyImageSharpSizes
        }
      }
    }
    site {
      siteMetadata {
        title,
        description,
        siteUrl
      }
    },
    technologies: allTechnologiesJson {
      entries: edges {
        technology: node {
          icon,
          name
        }
      }
    },
    socialLinks: allSocialLinksJson {
      entries: edges {
        socialLink: node {
          icon,
          href,
          name
        }
      }
    }
  }

`
